.main-loader{
    position: absolute;
    z-index: 1000;
    height: 100%;
    width: 100%;
    top: 0;
    left: 0;
    background-color: rgba(255,255,255,1);
}

.single-detail-label{
    color: gray;
    font-weight: bold;
    font-size: 0.75rem;
    margin-bottom: 0
}

.single-detail-value{
    font-size: 1.5rem;
}
.single-detail-value-small{
    font-size: 1rem;
}

.bg-correction {
    background-color: #d4edda!important;
}

.bg-undone {
    background-color: #f8d7da!important;
}