.products-container{
    min-height: 80vh;
}
.product-container{
    min-height: 80vh;
}

.product-list{
    width: 100%;
}

.product-details-container{
    /* margin: 40px 20px 40px 20px; */
}