.codeName {
    font-size: 20px;
    font-weight: bold;
    overflow: hidden;
}

@media screen {
    .newcontainerToPrint {
        text-align: center;
        width: 60%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }
    /* Stare style */
    .fontSize {
        font-size: 20px
    }
    .imgWidth {
        width: 400px;
    }
    .to-print-input {
        border: none;
        width: 80px
    }
    /* Nowe style */
    .newimgWidth {
        width: 300px;
    }
    .printText {
        font-size: 12px;
    }
    .print-only {
        display: none;
    }
}

@media print {
    /* Stare style */
    .seriesFontSize {
        font-size: 30px;
    }
    .dataFontSize {
        font-size: 30px
    }
    .imgWidth {
        width: 600px;
        /* padding-left: 100px;
        padding-right: 100px; */
        /* width: 500px; */
        /* height:500px; */
    }
    .containerToPrint {
        margin-top: 25px;
        /* border:1px solid green; */
        width: 700px;
        height: 500px;
    }
    .to-print-input {
        border: none;
        width: 170px
    }
    @page {
        /* border:1px solid red; */
        margin-left: 0px;
        margin-right: 0px;
        margin-top: 0px;
        margin-bottom: 0px;
    }
    /* Nowe style */
    .newcontainerToPrint {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: flex-start;
        padding: 15px;
    }
    .newseriesFontSize {
        font-size: 20px;
    }
    .newdataFontSize {
        font-size: 20px
    }
    .newimgWidth {
        margin-top: 40px;
        width: 90vw;
    }
    .printText {
        padding-left: 40px;
        font-size: 40px;
        text-align: start;
        line-height: 2;
    }
    .screen-only {
        display: none;
    }
    .codeName {
        padding-left: 25px;
        line-height: 100%;
        font-size: 90px;
        text-align: center;
        max-height: 194px;
        margin-top: 35px;
        margin-bottom: 70px;
    }
    .img-container {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
    }

}